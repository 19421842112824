import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import MensajesES from '../lang/es.json';
import MensajesEN from '../lang/en.json';

const langContext = React.createContext<any>(null);

const LangProvider = ({children}: any) => {

    let messagesDefault;
    let langCodeDefault : string;
    const langDefault = localStorage.getItem('lang');

    if (langDefault) {
        switch (langDefault) {
            case 'es':
                messagesDefault = MensajesES;
                langCodeDefault = 'es';
                break;
            case 'en':
                messagesDefault = MensajesEN;
                langCodeDefault = 'en';
                break;
            default:
                messagesDefault = MensajesEN;
                langCodeDefault = 'en';
        }
    } else {
        messagesDefault = MensajesEN;
        langCodeDefault = 'en';
    }

    const [messages, setMessages] = useState<any>(messagesDefault);
    const [locale, setLocale] = useState<string>(langCodeDefault);

    /**
     * Cambia el idioma de la página
     * @param lang Idioma a cambiar
     */
    const changeLanguage = (lang: string) => {
        localStorage.setItem('lang', lang);

        switch (lang) {
            case 'es':
                setMessages(MensajesES);
                setLocale('es');
                break;
            case 'en':
                setMessages(MensajesEN);
                setLocale('en');
                break;
            default:
                setMessages(MensajesEN);
                setLocale('en');
        }
    }

    return (
        <langContext.Provider value={{changeLanguage: changeLanguage, currentLang: locale}}>
            <IntlProvider locale={locale} messages={messages}>
                {children}
            </IntlProvider>
           
        </langContext.Provider>
    );
}

export {LangProvider, langContext};