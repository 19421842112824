import React from 'react';
import './App.css';

import Body from './components/body';
import Header from './components/header';
import Footer from './components/footer';

class App extends React.Component {

  private playVideo () {
    const video = document.getElementById('video-background') as HTMLVideoElement;
    if(video.paused) {
      video.play();
    } else {
    }
  }
  componentDidMount() {
    document.body.addEventListener("touchstart", this.playVideo);
  }

  render(): React.ReactNode {
    return (
      <div className="App">
        <video id='video-background' className='video-background' playsInline autoPlay muted loop>
          <source src="https://firebasestorage.googleapis.com/v0/b/micro-fccdb.appspot.com/o/videos%2Fbackgrounds%2Fvideo-background-1.webm?alt=media" type="video/webm"/>
          <source src="https://firebasestorage.googleapis.com/v0/b/micro-fccdb.appspot.com/o/videos%2Fbackgrounds%2Fvideo-background-1.mp4?alt=media" type="video/mp4"/>
        </video>
        <div className='background-overlay'/>
        <div className='top-container'>
          <Header />
          <Body />
        </div>
        <div className='bottom-container'>
          <Footer />
        </div>
      </div>
  );
  }
}

export default App;
