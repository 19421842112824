import React, { useContext } from 'react';
import './styles/header.css';
import { FormattedMessage } from 'react-intl';
import { langContext } from '../context/langContext';


function Header() {

  const context = useContext(langContext);

  const lang = context.currentLang === 'es' ? 'en' : 'es';

    return (
        <header className="App-header">
          <a href="#" className='btn nav' onClick={() => context.changeLanguage(lang)}>
            <FormattedMessage 
              id='header.language'
            />
          </a>
        </header>
    );
}

export default Header;