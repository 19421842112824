import React, { useState } from 'react';
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable  } from 'firebase/functions';

const firebaseContext = React.createContext<any>(null);

const FirebaseProvider = ({children}: any) => {

    let _functions : any = {};

    const firebaseConfig = {
        apiKey: "AIzaSyDxPDJBR6cqVHkF6vsnXJdF5UYYeBHu0t0",
        authDomain: "micro-fccdb.firebaseapp.com",
        projectId: "micro-fccdb",
        storageBucket: "micro-fccdb.appspot.com",
        messagingSenderId: "159615230573",
        appId: "1:159615230573:web:1b927d99ff3fe88b9d1564"
      };
      
      // Initialize Firebase
    const _app = initializeApp(firebaseConfig);
    const functions = getFunctions(_app);
    _functions.registerEmail = httpsCallable(functions, 'registerEmail');

    return (
        <firebaseContext.Provider value={{functions: _functions}}>
            {children}
        </firebaseContext.Provider>
    );
}

export {FirebaseProvider, firebaseContext};