import React from 'react';
import { FormattedMessage } from 'react-intl';
import './styles/footer.css';

/*<img className='logo-img' src="https://firebasestorage.googleapis.com/v0/b/micro-fccdb.appspot.com/o/images%2Flogos-supported%2Fnatgeo-white.png?alt=media" alt="National Geographic" />*/

function Footer() {
    return (
        <footer className='content footer'>
            <div className='supported-text-container'>
                <p>
                    <FormattedMessage 
                        id='footer.sponsor.title'
                    />
                </p>
            </div>
            <div className='logos-container'>
                <p className='logo-txt'>National Geographic Society</p>
                <img className='logo-img' src="https://firebasestorage.googleapis.com/v0/b/micro-fccdb.appspot.com/o/images%2Flogos-supported%2FLogo_UNALM.png?alt=media" alt="UNALM" />
                <img className='logo-img' src="https://firebasestorage.googleapis.com/v0/b/micro-fccdb.appspot.com/o/images%2Flogos-supported%2Flogo-michigan.png?alt=media" alt="Life Sciences Institute" />
                <img className='logo-img' src="https://firebasestorage.googleapis.com/v0/b/micro-fccdb.appspot.com/o/images%2Flogos-supported%2FLogo_Boiling%20River%20Project.jpeg?alt=media" alt="Boiling River Project" />
                <img className='logo-img' src="https://firebasestorage.googleapis.com/v0/b/micro-fccdb.appspot.com/o/images%2Flogos-supported%2FLogo%20Ciencia%20Int%201.png?alt=media" alt="Amazon Research Int." />
            </div>
        </footer>
    );
}

export default Footer;