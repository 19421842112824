import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { firebaseContext } from '../context/firebaseContext';
import './styles/body.css';

class Body extends React.Component<any, any> {

  static contextType: React.Context<any> = firebaseContext;

  constructor(props: any) {
    super(props);

    this.state = {
      isFormSend: false,
      userEmail: ''
    };
  }

  private registerEmail(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();

    if (this.state.userEmail !== '') {
      this.setState({
        isFormSend: true
      });

      const _app = this.context as any;

      _app.functions.registerEmail({email: this.state.userEmail});
    }
  }

  private onUserEmailChanged(e: any) {
    this.setState({
      userEmail: e.target.value
    });
  } 

  public render() {
      return (
          <section className='content'>
            <div className='container center'>
              <img src="https://firebasestorage.googleapis.com/v0/b/micro-fccdb.appspot.com/o/images%2Flogo.svg?alt=media" 
                alt="Logo" className='logo center' width='20%'/>
            </div>
            <div className='container center'>
              <h1 className='title'>MICRO <b>AMAZON</b></h1>            
            </div>
            <div className='container center'>
              <h3 className='message'>
                <FormattedMessage 
                  id='app.title'
                  values={{
                    boldThis: chunks => <b>{chunks}</b>,
                    sup: chunks => <sup>{chunks}</sup>
                  }}
                />
              </h3>
            </div>
            <div className="container center">
              {this.state.isFormSend ? 
                <p className='thanks_message'>
                  <FormattedMessage 
                    id='app.thanks'
                  />
                </p>  
              :
                <form action="#" className='form'>
                  <FormattedMessage 
                    id='form.email.placeholder'
                  >
                    {txt => <input onChange={this.onUserEmailChanged.bind(this)} className='email' type="text" name="email" id="email" placeholder={txt.toString()}/>}
                  </FormattedMessage>
                  <button type="submit" className='submit' onClick={(e) => this.registerEmail(e) }>
                    <FormattedMessage 
                    id='form.submit' />
                  </button>
                </form>
              }
            </div>         
          </section>
      );
  }
}

export default Body;